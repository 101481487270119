import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function LectureContents(props) {

  // console.log ( props.target)
  // const tg = target;

  return (

      props.data.map((lecture, index) => {
        // console.log(lecture)
        // console.log(props.target + index)
        return (
          <div key = {props.target + index} style={{fontSize: "18px"}}>
          <Accordion key={props.target + index}>
            <AccordionSummary
              key={props.target + index}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel" + index + "a-content"}
              id={"panel" + index + "a-header"}
            >
            <Typography key={props.target  + index}>{lecture.title}</Typography>
            </AccordionSummary>
            <AccordionDetails key={props.target  + index}>
              <Typography key={"target" + index} variant="body2" color="text.secondary">
                수강대상 :  {lecture.target == "female" ? "자매" : lecture.target == "all" ? "형제/자매 공통" : lecture.target == "child" ? "형제/자매 공통(유아)" : lecture.target == "male" ? "형제" : "" }
              </Typography>
              {/* <Typography key={"instructor" + index} variant="body2" color="text.secondary">
              {lecture.instructor? "강사명 : " + lecture.instructor  : ""} 
              </Typography> */}
              <Typography key={"classroom" + index} variant="body2" color="text.secondary">
              {lecture.classroom? "강의실 : " + lecture.classroom  : ""} 
              </Typography>
              <Typography key={"max" + index} variant="body2" color="text.secondary">
              {lecture.max? "예상 인원 : " + lecture.max + "명" : ""} 
              </Typography>
              <Typography  key={"memo" + index}  variant="body2" color="text.secondary">
              {lecture.memo ? "참고 사항 : " + lecture.memo : ""} 
              </Typography>
              
            </AccordionDetails>
          </Accordion>     
         </div>          
        )        
      })

  );
}
