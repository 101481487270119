import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import prayList from "../contents/prayList"

export default function PrayList() {

  return (
    <Card >     
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" style={{ textAlign: "center", marginTop: "20px", marginBottom: "40px"}}>
          기도제목
        </Typography>
        {prayList.list.map((list, index) => {
          return(
          <div key={"prayList" + index}>
            <Typography variant="body2" color="text.secondary" style={{ fontWeight: "bold", fontSize: "18px"}}>
              {list.title}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontSize: "18px"}}>
              {list.words1}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontSize: "18px"}}>
            {list.words2}
            </Typography>
            <p></p>
          </div>
          )
        })}    
      </CardContent>      
    </Card>
  );
}
