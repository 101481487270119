import * as React from 'react';
import Typography from '@mui/material/Typography';

export default function Footer() {

  return (
    <>
    {/* <hr></hr> */}
    <div style={{ padding: "10px" }}>
    <Typography gutterBottom component="div" style={{ fontWeight: "bold", fontSize: "14px"}}>
        #빛과진리교회 #48회리커버리세미나 #상주세요 #말수 #떡으로만 #마사사
    </Typography>
    
    </div>
    </>
  );
}
