import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


export default function HelpModal(props) {

  const { open, close } = props;

  return (
    <Dialog
    open={open}
    onClose={close}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"문의사항"}
    </DialogTitle>
    <DialogContent>
      {/* <DialogContentText id="alert-dialog-description"> */}
      <Typography>선택강의 010-8972-4525 </Typography>
      <Typography>체육친교 010-4124-6914 </Typography>
      
      {/* </DialogContentText> */}
    </DialogContent>
    <DialogActions>
      <Button onClick={close}>닫기</Button>
    </DialogActions>
  </Dialog>      
  );
}
