import * as React from 'react';
import Typography from '@mui/material/Typography';
import rule1 from "../img/rule1.jpeg";
import rule2 from "../img/rule2.jpeg";
import rule3 from "../img/rule3.jpeg";
import rule4 from "../img/rule4.jpeg";
import rule5 from "../img/rule5.jpeg";
import rule6 from "../img/rule6.jpeg";
import rule7 from "../img/rule7.jpeg";
import { Mobile, Desktop } from "../lib/mediaQuery"
import SimpleImageSlider from "react-simple-image-slider";
import Box from '@mui/material/Box';

export default function Rules() {


  const images = [
    { url: rule1 },
    { url: rule2 },
    { url: rule3 },    
    { url: rule4 },
    { url: rule5 },
    { url: rule6 },
    { url: rule7 },
  ];
  
  return (
    <Box>
      <Mobile>
        <SimpleImageSlider
          width={window.innerWidth}
          height={580}
          images={images}
          showBullets={true}
          showNavs={true}
          navSize={55}
          // autoPlay={true}
          // autoPlayDelay={3}
        />
      </Mobile>
      <Desktop>
        <SimpleImageSlider
          width={800}
          height={window.innerHeight}
          images={images}
          showBullets={true}
          showNavs={true}
          navSize={55}
          // autoPlay={true}
          // autoPlayDelay={3}
        />
      </Desktop>
    </Box>
    // <>
    // <div style={{ padding: "10px" }}>
    //     <Typography gutterBottom variant="h5" component="div" style={{ textAlign: "center", marginTop: "20px", marginBottom: "40px"}}>
    //     이용수칙
    //     </Typography>
    //       {/* <Typography style={{marginTop: "20px", marginBottom: "20px", fontWeight: "bold"}}>[]</Typography>   */}
    //       <div>
    //         <p style={{fontSize: "18px"}}>
    //           <b>바코드</b>
    //           <br></br><br></br>
    //           수련회 참여 바코드 찍기<br></br>
    //           - 교회입구 바코드 리더기<br></br>
    //           - 금요일과 토요일 각각 <br></br>
    //           하루 한번은 꼭 찍어주세요<br></br><br></br>

    //           식사할 때 배식대에서 <br></br>
    //           바코드 꼭 찍기
    //           </p>
    //           <br></br><br></br>

    //   <p>
    //   <b>장소사용</b>
    //   <br></br><br></br>
    //   분임토의 및 선택강의를<br></br>
    //   마친 후 책걸상은 예배대형으로 원상복구 하기<br></br><br></br>

    //   세팅 된 자리는 <br></br>
    //   임의로 이동하지 않기
    //   </p>
    //   <br></br>
    //   <p>
    //   <b>본당자리</b>
    //   <br></br><br></br>
    //   본당일정 종료 후 :<br></br> 
    //   개인짐은 항상 챙겨주세요<br></br>
    //   분실우려가 있습니다<br></br>
    //   (18:00시에 일괄 정리됨)<br></br><br></br>

    //   리허설 및 세팅 후 본당개방 :<br></br>
    //   18:30분부터 선착순 입장<br></br>
    //   자리 맡아주기 금지
    //   </p>
    //   <br></br>
    //   <p>
    //   <b>식사</b><br></br><br></br>

    //   저녁식사 장소사용 안내<br></br><br></br>

    //   본당에서 식사 시 <br></br>
    //   17:00-18:00까지만 가능<br></br>
    //   (예배 세팅과 리허설이 있어요)<br></br><br></br>

    //   교육관(5층, 6층, 7층) 및  소예배실은 17:00-18:30까지<br></br><br></br>

    //   식사배식 시간 (지킬 때 더 빠름)<br></br>
    //   17:00- OT반, 형제, 자녀양육<br></br>
    //   17:30- 자매<br></br>
    //   18:30-19:00 take-out만 가능<br></br><br></br>


    //   <b>식사</b><br></br><br></br>

    //   식사배식 (동선 고려하여 이용)<br></br><br></br>

    //   배식 : 소예배실 쪽 주방<br></br>
    //   식사 : 본당, 소옙(유아동반)<br></br><br></br>

    //   배식 : 본당자매 쪽 주방 <br></br>
    //   식사 : take-out용<br></br><br></br>

    //   배식 : 교육관 7층 <br></br>
    //   식사 : 교육관 5,6,7층<br></br><br></br>

    //   식사대용 : 주방에서 드려요
    //   </p>
    //   <br></br>
    //   <p>
    //   <b>간식</b><br></br><br></br>

    //   메시지 간식 (18:30~22:00)<br></br>
    //   본당, 교육관 입구 <br></br>
    //   1인(물1병, 사탕2-3개, 과자1개)<br></br><br></br>

    //   아이스크림(토요일 10~21시)<br></br>
    //   + 간데메 공원 옆 얼음땡           <br></br>      
    //     아이스크림 할인점에 간다.<br></br>
    //   + 아이스크림 1개를 고른다.<br></br>
    //     1,000원 이하(초과금액 본인부담)    <br></br> 
    //   + 쿠폰을 쿠폰!하고 낸다
    //   </p>
    //   <br></br>
    //   <p>
    //   <b>안내</b><br></br><br></br>

    //   얼음땡 아이스크림할인점 위치<br></br><br></br>


    //   (지도) <br></br><br></br>


    //   귀가 시에 민원 방지를 위해 대화는 자제 바랍니다.
    //   </p>

    //       </div> 
    //     </div>
    // </>
  );
}

