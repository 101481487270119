import * as React from 'react';
import { Cookies } from "react-cookie";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import Box from '@mui/material/Box';


export default function BarcodeModal(props) {

  const { open, close, save, preNumber} = props;

  const [phone, setPhone] = React.useState("");

  React.useEffect(() => {

    if(open){
      if(preNumber){
        setPhone(preNumber)
      }
    }

  }, [open]);

  const saveData = () => {
    save(phone);
  };

  const onHandlePhoneNumber = (e) => {
    setPhone(e.target.value)
  }

  return (   
    <Dialog
    open={open}
    onClose={close}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"휴대폰번호를 입력해주세요"}
    </DialogTitle>
    <DialogContent>
      {/* <DialogContentText id="alert-dialog-description"> */}
      <FormControl sx={{ width: '25ch' }}>
        <OutlinedInput placeholder="숫자만 입력해주세요" value={phone} onChange={onHandlePhoneNumber}/>       
      </FormControl>
      
      {/* </DialogContentText> */}
    </DialogContent>
    
    <DialogActions>
      <Button onClick={saveData}>인증</Button>
      <Button onClick={close}>닫기</Button>
    </DialogActions>
  </Dialog>      
  );
}
