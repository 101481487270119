import React from "react";
import Template from "../component/Template"

const Notice = () => {

  return (
    <>
      <div
      />
      <Template/> 
      <div />
    </>
  );
};

export default Notice;