import * as React from 'react';
import Typography from '@mui/material/Typography';

export default function KidsCare() {

  return (
    <>
        <div style={{ padding: "10px" }}>
        <Typography gutterBottom variant="h5" component="div" style={{ textAlign: "center", marginTop: "20px", marginBottom: "40px"}}>
        분임토의 유섬안내
        </Typography>
          {/* <Typography style={{marginTop: "20px", marginBottom: "20px", fontWeight: "bold"}}>[분임토의 유섬안내]</Typography>   */}
          <p style={{fontSize: "18px"}}>
풍성한 수련회를 위해 기도합니다. <br></br><br></br>

마 10:42.<br></br><br></br>
또 누구든지 제자의 이름으로 이 소자 중 하나에게 냉수 한 그릇이라도 주는 자는 내가 진실로 너희에게 이르노니 그 사람이 결단코 상을 잃지 아니하리라 하시니라
<br></br><br></br>
위 말씀을 약속으로 분임토의 하실 때 유섬을 진행합니다. <br></br>
에어바운스를 대여해서 물놀이를 준비중에 있습니다. <br></br><br></br>

장소 : 빛과진리교회 필로티 <br></br>
시간 : 오전 (9시~12:30분) / 오후 (1시 30분~5시)<br></br>
준비물 : 물놀이 도구<br></br>
문의 : 오전 (김기쁨 010-7111-6711) / 오후 (김정은 010-8704-5819)<br></br><br></br>

비오지 않도록, 아이들이 안전하게 <br></br>
즐겁게 잘 있다가 갈 수 있도록 기도 부탁드립니다. </p> 
        </div>
    </>
  );
}
