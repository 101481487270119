import * as React from 'react';
import Typography from '@mui/material/Typography';


export default function BarcodeManual() {
  
  return (
    <>    
    <div style={{ padding: "10px" }}>
        <Typography gutterBottom variant="h5" component="div" style={{ textAlign: "center", marginTop: "20px", marginBottom: "40px"}}>
        입장 바코드 사용방법
        </Typography>
        
          <div>        
             <p>               
               <br></br>
               현재 화면 오른쪽 하단에 "입장 바코드" 라고 적힌 버튼을 누른 뒤 수련회 등록하셨을 때 입력한 휴대폰번호를 입력해주세요. "인증" 버튼을 누르시면 잠시후 바코드 페이지가 열리게 됩니다. <br></br><br></br>
               처음 한번만 입력하시면 다음 번 입장시에는 휴대폰 번호가 저장되어 빠르게 바코드를 열고 확인하실 수 있습니다. <br></br><br></br>            
               등록 후 문자로 받으신 웹사이트 주소로 직접 들어가서 확인하셔도 됩니다.<br></br><br></br>
            </p>
               <br></br>

            <p>
              <b>중복으로 등록된 휴대폰번호 인증 안내</b>
              <br></br><br></br>       
              수련회 등록시에 하나의 휴대폰번호로 자녀, 팔로워, 친구, 배우자 등 다른 인원까지 등록하여 휴대폰번호만으로 입장 바코드를 확인할 수 없는 경우 다음과 같이 하시면 확인하실 수 있습니다. <br></br><br></br>
              -첫번째 등록자: 등록한 휴대폰번호 (11자리)<br></br> 
              -두번째 등록자: 등록한 휴대폰번호 + 1 (12자리)<br></br>
              -세번째 등록자: 등록한 휴대폰번호 + 2 (12자리)<br></br>
              -이후 규칙은 동일합니다.<br></br><br></br>

              예를 들어 내 휴대폰 번호가 01012345678 이고 자녀 2명을 포함하여 3명이 동일한 번호로 등록한 경우<br></br>
              
              <br></br>
              -본인: 01012345678<br></br> 
              -자녀1: 010123456781<br></br>
              -자녀2: 010123456782<br></br>
            </p>   <br></br>
             
            <p>
              <b>번호가 없거나 에러가 발생하는 경우</b>     
              <br></br> <br></br>
            안내 데스크로 문의 부탁드립니다.
            </p>
            <br></br> <br></br>
                      
        </div>
    </div>    
    </>
  );
}