import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Notice from "./pages/Notice";

function App() {
  return (
    <div>
       <BrowserRouter>
        <Routes>
          <Route path='/notice' element={<Notice />} />
          <Route path='/' element={<Notice />} />
          <Route path="/" render={() => <Navigate to="/notice" />} />          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
