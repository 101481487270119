import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { Cookies } from "react-cookie";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import menu from '../contents/menu';
import Button from '@mui/material/Button';
import LectureContents from "./LectureContents";
import poster from "../img/LNT_home.png";
import timeTable from "../img/timeTable.png";
import qt from "../img/qt.png";
import PrayList from "./PrayList";
import Footer from './Footer';
import KidsCare from './KidsCare';
import Rules from './Rules';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import lecture from "../contents/lecture"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import HelpModal from './HelpModal';
import BarcodeModal from './BarcodeModal';
import BarcodeManual from './BarcodeManual'
import YouTube from "react-youtube"

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: "16px",
  marginTop: "-6px",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  // marginRight: "36px",
  // marginLeft: "10px",
  width: '100%',
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(3),
  //   width: 'auto',
  // },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '7px'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
    zIndex: -10,
    backgroundColor: "lightgray",
    borderRadius: "25px",
    marginTop: "15px"

  },
}));

// 바코드 입장
async function getIvcode(value) {
  // console.log(value);

  const formData = new FormData()
    
  formData.append("phone", value) //files[0] === upload file

  const response = await axios({
    method: "POST",
    baseURL: '/api', 
    headers: {
      "Content-Type": "multipart/form-data", // Content-Type을 반드시 이렇게 하여야 한다.
    },     
    data: formData
  });
  // console.log(response.data)


  if(response.status === 200){

    const code = response.data?.[0].code;

    if (code === 202 || code === 201) {
      // console.log(response.data?.[0].msg, {theme: "colored"})
      toast.warn(response.data?.[0].msg, {theme: "colored", autoClose: 3000});
    }

    const iVcode = response.data?.[0].iVcode;  
    
    if(iVcode){
      const url = `https://sjinfotec.com/recovery-seminar/index_pre.asp?vcode=${iVcode}`;
      window.location.href = url;  
    }

  }
}

export default function Template() {
  const [value, setValue] = React.useState(0);
  const [contentsNum, setContentsNum] = React.useState(0);
  const [lectureList, setLectureList] = React.useState(lecture["common"]);
  const [helpOpen, setHelpOpen] = React.useState(false);
  const [barCodeOpen, setBarCodeOpen] = React.useState(false);
  const [phone, setPhone] = React.useState("");

  const cookies = new Cookies();

  const handleClose = () => {
    setHelpOpen(false);
  };

  const onHandleContents = (e) => {
    // console.log(e.target.value)
    setContentsNum(e.target.value);
  }

  const onHandleSearch = (e) => {

    setContentsNum(-1)
   const value = e.target.value;
   const dataList = lecture["common"];

    const result = dataList.filter(object => {
          if(object.title.indexOf(value) > -1){
            return object;
          }
          return null;
        });

    setLectureList(result)
  }

  const onHandleHelp = () => {    
    setHelpOpen(!helpOpen)
  }

  const onHandleBarcodeModal = () => {    

   const preNumber = cookies.get('phone')
   if(preNumber){
    setPhone(preNumber)
   }
    
    setBarCodeOpen(!barCodeOpen)
  }

  const onCloseBarcodeModal = () => {    
    setBarCodeOpen(false)
  }

  const onHandleSave = (value) => {
    // console.log(value)
    // localStorage.setItem('phone', value)

    cookies.set( "phone", value )
    setBarCodeOpen(!barCodeOpen);

    getIvcode(value);
  }

  return (  
    <>
    <ToastContainer/>
    <BarcodeModal open={barCodeOpen} close={onCloseBarcodeModal} save={onHandleSave} preNumber={phone}></BarcodeModal>
    <HelpModal open={helpOpen} close={handleClose}></HelpModal>
    <Box sx={{ width: '100%', padding: '10px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {menu["menu"].map((subItem, subIndex) => {
            return (
              <Button key={"button" + subIndex} size="small" variant="outlined" style={{marginRight: "8px",marginBottom: "8px", borderRadius : "26px", borderColor: "rgb(0, 160, 178)", color: "rgb(0, 160, 178)", fontWeight:"800", border: "1.5px solid" }} value={subIndex} onClick={onHandleContents}>{subItem.text}</Button>
            )
        })
        }
      </Box>
      <div>
 
        {/* 홈 */}
        {contentsNum == 0 ?
          <>
            <img src={poster} style={{  align: "center", width: "100%" }} />
            {/* 세미나 소개 영상 */}
            <YouTube
              videoId={'CJ9n6keX534'}
              opts={{
                width: window.innerWidth,
                height: "315",
                playerVars: {
                  autoplay: 1, //자동재생 O
                  rel: 0, //관련 동영상 표시하지 않음 
                  modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                },
              }}
                //이벤트 리스너 
               onEnd={(e)=>{e.target.stopVideo(0);}} 
            />
            <br></br> <br></br>
            <Footer></Footer>
          </>
        :null
        }

        {/* 시간표 */}
        {contentsNum == 1 ?
        <>
          <img src={timeTable} style={{ align: "center", width: "100%" }} />
          <Footer></Footer>
        </>
        :null
        }

        {/* 선강/체친 */}
       {contentsNum == 2 || contentsNum == -1 ?
            <>
            <div style={{ display: "flex", marginRight: "25px"}}>
               <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="선택강의를 입력하세요"
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={onHandleSearch}
                  />
                </Search>
              <IconButton edge="end" aria-label="help" onClick= {onHandleHelp}>
                <HelpOutlineIcon />
              </IconButton>
            </div>
            {contentsNum == 2 ?
              <LectureContents key={"common"} target={"common"} data={lecture["common"]}/>
              :null
            }
            {contentsNum == -1 ?
              <LectureContents key={"search"} target={"search"} data={lectureList}/>
            :null
            }
            <Footer></Footer>
            </>
          :null
       }

        {/* 유섬안내 */}
        {contentsNum == 3 ?
        <>
          <KidsCare></KidsCare>
          <Footer></Footer>
        </>
          :null
        }

        {/* 이용수칙 */}
        {contentsNum == 4 ?
        <>
          <Rules></Rules>
          <Footer></Footer>
        </>

        :null
        }

        {/* 기도제목 */}
        {contentsNum == 5 ?
        <>
            <PrayList/>
            <Footer></Footer>
        </>
        :null
        }

         {/* 경시/암송/하트 */}
         {contentsNum == 6 ?
          <>
            <img src={qt} style={{  align: "center", width: "100%" }} />
            <Footer></Footer>
          </>
        :null
        }

         {/* 바코드 사용안내 */}
         {contentsNum == 7 ?
          <>
            <BarcodeManual/>
            <Footer></Footer>
          </>
        :null
        }
          <Fab sx={fabStyle} variant="extended" size="small" style={{ backgroundColor: "rgb(255,243,0)", opacity: "0.5", padding:"30px", position: "fixed", width:"80px", height: "80px", borderRadius: "50%", right: "30px"}} aria-label="add" onClick={onHandleBarcodeModal}>                     
         </Fab>         
         <span style={{position: "fixed", color: "#000", bottom: "39px", right: "51px", fontSize: "14px", textAlign: "center", fontWeight:"bold", zIndex: "9999"}} onClick={onHandleBarcodeModal}>입장<br></br>바코드</span> 
      </div>
    </Box>
    </>
  );
}
